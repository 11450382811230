import { useUser } from "@clerk/nextjs";
import {
  BeakerIcon,
  BoltIcon,
  CpuChipIcon,
  EyeSlashIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCurrency } from "../hooks/useCurrency";
import { getPriceForCurrency } from "../utils/getPriceForCurrency";
import posthog from "posthog-js";
import { GiftIcon } from "lucide-react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/solid";
import { PWAInstallPrompt } from "../components/PWAInstallPrompt/PWAInstallPrompt";

const LandingPage = () => {
  const router = useRouter();
  const { isSignedIn } = useUser();
  const currencyCode = useCurrency();

  const price = currencyCode ? getPriceForCurrency(currencyCode) : null;

  useEffect(() => {
    if (isSignedIn) {
      router.push("/terminal").catch((e) => console.error(e));
    }
  }, [isSignedIn, router]);

  const referralCode = router.query?.ref;

  useEffect(() => {
    if (typeof referralCode === "string") {
      window.localStorage.setItem(
        "referral",
        JSON.stringify({ referralCode, referredAt: new Date().getTime() })
      );
    }
  }, [referralCode]);

  useEffect(() => {
    posthog.capture("Landing Page Visited");
  }, []);

  return (
    <>
      <Head>
        <title>POD | Pinnacle Odds Dropper</title>
        <meta
          name="description"
          content="Track the movements of Pinnacle's odds and set up alerts"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content="Pinnacle Odds Dropper" key="title" />
        <meta
          property="og:description"
          content="Get notified when Pinnacle's odds drop"
        />
        <meta property="og:url" content="https://pinnacleoddsdropper.com" />
        <meta
          property="og:image"
          content="https://s3.amazonaws.com/appforest_uf/f1675891443939x751057183610460000/socialmedia-1675887595542x750744149380563000.png"
        />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Pinnacle Odds Dropper" />
        <meta
          property="twitter:url"
          content="https://pinnacleoddsdropper.com"
        />
        <meta
          property="twitter:description"
          content="Get notified when Pinnacle's odds drop"
        />
        <meta
          property="twitter:image"
          content="https://s3.amazonaws.com/appforest_uf/f1675891443939x751057183610460000/socialmedia-1675887595542x750744149380563000.png"
        />
        <meta name="color-scheme" content="light" />
      </Head>

      <div className="flex min-h-screen w-screen items-center justify-center bg-gray-50 font-display">
        <div className="z-10">
          {referralCode && (
            <div className="mt-4 flex h-7 w-full items-center justify-center rounded-sm bg-green-100 text-center text-sm text-green-700">
              <GiftIcon className="h-4" />
              <p>
                You were referred by{" "}
                <span className="font-semibold">{referralCode}</span>
              </p>
            </div>
          )}
          {!referralCode && (
            <div className="mt-4 flex w-full items-center justify-between gap-2 rounded-sm bg-green-100 px-4 py-2 text-center text-sm text-green-700 md:justify-center">
              <DevicePhoneMobileIcon className="h-4" />
              <span className="mr-2 font-semibold">Install the mobile app</span>
              <PWAInstallPrompt
                trigger={
                  <button className="rounded-sm bg-emerald-600 px-2 py-0.5 text-emerald-50 hover:bg-emerald-700 md:py-0">
                    Install
                  </button>
                }
              />
            </div>
          )}
          <nav className="flex max-w-4xl items-center justify-between px-4 pt-4">
            <Link
              href="/"
              className="mr-4 font-display text-sm font-bold text-gray-700"
            >
              Pinnacle Odds Dropper
            </Link>

            <div className="flex items-center gap-6">
              <Link
                href="/guides"
                className="text-sm text-gray-600 hover:text-blue-700"
              >
                Guides
              </Link>
              <Link
                href="/sign-in"
                className="whitespace-nowrap text-sm text-gray-600 hover:text-blue-700"
              >
                Log in
              </Link>
              <Link
                href="/sign-up"
                className="whitespace-nowrap rounded-md bg-gradient-to-r from-slate-700 to-black px-4 py-1 text-sm text-white hover:opacity-90"
              >
                Sign up
              </Link>
            </div>
          </nav>
          <div className="mx-auto mb-10 mt-20 max-w-md px-2.5 sm:max-w-2xl sm:px-0">
            <header className="px-1">
              <div className="text-center">
                <h1 className="mt-5 font-display text-4xl font-extrabold leading-[1.15] text-black sm:text-6xl sm:leading-[1.15]">
                  Get notified when
                  <br />
                  <span className="bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 bg-clip-text text-transparent">
                    {`Pinnacle's odds drop`}
                  </span>
                </h1>
                <h2 className="mt-5 font-display text-gray-600 sm:text-xl">
                  {`Notifications mean you're first to know when odds change on
        Pinnacle. Powerful custom filters put you in control.`}
                </h2>
              </div>

              <Link
                href="/sign-up"
                className="mx-auto mt-5 block w-fit rounded-md border border-transparent bg-gradient-to-r from-blue-600 to-cyan-600 px-4 py-3 font-medium text-white transition-all hover:border-blue-700 hover:bg-white hover:bg-clip-text hover:text-transparent"
              >
                Set up your alerts
              </Link>
            </header>

            <article className="mt-12 flex gap-4 rounded-lg border-2 border-gray-100 p-4 py-6 text-left shadow-md backdrop-blur-sm">
              <div className="relative h-16 w-16 shrink-0 overflow-hidden rounded-full">
                <Image
                  src="/images/landing/clayton.jpeg"
                  alt="Photo of Clayton"
                  fill
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div>
                <p className="sm:text-md text-sm text-gray-700">{`"It's so quick to show changing odds and I love how you can set up multiple filters...  it's just good, thank you for building this"`}</p>
                <p className="mt-1 text-xs text-gray-600 sm:text-sm">{`Clayton (London) - member for 3 months`}</p>
              </div>
            </article>

            <section className="mb-32 mt-32 px-6">
              <div className="mx-auto flex w-fit gap-8 sm:gap-16">
                <div className="max-w-sm">
                  <h3 className="overflow-auto bg-gradient-to-r from-slate-700 to-black bg-clip-text text-xl font-bold leading-tight tracking-tight text-transparent">
                    Lightning fast notifications
                  </h3>
                  <p className="text-md mt-3 leading-tight text-gray-600">{`When odds drop on Pinnacle, you'll be notified in a matter of seconds.`}</p>
                </div>
                <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded-lg border-2 border-gray-400 bg-gradient-to-r from-slate-700 to-black transition-all hover:rotate-45">
                  <BoltIcon className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="mx-auto mt-20 flex w-fit gap-8 sm:gap-16">
                <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded-lg border-2 border-gray-400 bg-gradient-to-r from-slate-700 to-black transition-all hover:rotate-45">
                  <CpuChipIcon className="h-8 w-8 text-white" />
                </div>
                <div className="max-w-sm">
                  <h3 className="overflow-auto bg-gradient-to-r from-black to-slate-500 bg-clip-text text-xl font-bold leading-tight tracking-tight text-transparent">
                    Multiple custom filters
                  </h3>
                  <p className="text-md mt-3 leading-tight text-gray-600">{`Add multiple custom filters so that you only get alerted for odds drops that match your criteria.`}</p>
                </div>
              </div>

              <div className="mx-auto mt-20 flex w-fit gap-8 sm:gap-16">
                <div className="max-w-sm">
                  <h3 className="overflow-auto bg-gradient-to-r from-slate-700 to-black bg-clip-text text-xl font-bold leading-tight tracking-tight text-transparent">
                    Control over intervals
                  </h3>
                  <p className="text-md mt-3 leading-tight text-gray-600">{`See how the odds have changed in the last few seconds, minutes, or days.`}</p>
                </div>
                <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded-lg border-2 border-gray-400 bg-gradient-to-r from-slate-700 to-black transition-all hover:rotate-45">
                  <BeakerIcon className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="mx-auto mt-20 flex w-fit gap-8 sm:gap-16">
                <div className="flex h-20 w-20 shrink-0 items-center justify-center rounded-lg border-2 border-gray-400 bg-gradient-to-r from-slate-700 to-black transition-all hover:rotate-45">
                  <EyeSlashIcon className="h-8 w-8 text-white" />
                </div>
                <div className="max-w-sm">
                  <h3 className="overflow-auto bg-gradient-to-r from-black to-slate-500 bg-clip-text text-xl font-bold leading-tight tracking-tight text-transparent">
                    Hide matches
                  </h3>
                  <p className="text-md mt-3 leading-tight text-gray-600">{`Don't want to keep getting alerted for the same match - easily hide it with one click.`}</p>
                </div>
              </div>
            </section>

            <article className="mt-12 flex flex-col items-center gap-4 rounded-lg border-2 border-gray-100 p-4 py-6 text-center shadow-md backdrop-blur-sm">
              <div className="relative h-16 w-16 shrink-0 overflow-hidden rounded-full">
                <Image
                  src="/images/landing/shane.jpeg"
                  alt="Photo of Shane"
                  fill
                  style={{
                    objectFit: "cover",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div className="max-w-lg">
                <p className="sm:text-md text-sm text-gray-700">{`"Really glad to have found this tool, it's really helped me increase the number of value bets I find and place"`}</p>
                <p className="mt-1 text-xs text-gray-600 sm:text-sm">{`Shane (Australia) - member for 1 month`}</p>
              </div>
            </article>

            <section className="mt-32 px-6">
              <h4 className="mb-10 bg-gradient-to-r from-slate-700 to-black bg-clip-text text-center text-xl font-bold text-transparent">
                Frequently Asked Questions
              </h4>

              <div className="flex gap-4">
                <div className="w-1/2">
                  <p className="sm:text-md text-sm font-semibold text-gray-700">
                    What sports do you support?
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="sm:text-md text-sm text-gray-600">
                    Soccer, Tennis, Basketball, Hockey, American football, MMA,
                    and Baseball.
                  </p>
                </div>
              </div>

              <div className="mt-10 flex gap-4">
                <div className="w-1/2">
                  <p className="sm:text-md text-sm font-semibold text-gray-700">
                    Do you support pre-match and in-play?
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="sm:text-md text-sm text-gray-600">
                    Yes, we support pre-match and in-play realtime odds and
                    pre-match alerts.
                  </p>
                </div>
              </div>

              <div className="mt-10 flex gap-4">
                <div className="w-1/2">
                  <p className="sm:text-md text-sm font-semibold text-gray-700">
                    Which markets do you support?
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="sm:text-md text-sm text-gray-600">
                    We will soon support all markets that appear on Pinnacle. At
                    the moment we support moneyline, totals, and spreads.
                  </p>
                </div>
              </div>

              {/* <div className="mt-10 flex gap-4">
            <div className="w-1/2">
              <p className="sm:text-md text-sm font-semibold text-gray-700">
                {`I've joined the waitlist, how long will it take before I get in?`}
              </p>
            </div>
            <div className="w-1/2">
              <p className="sm:text-md text-sm text-gray-600">
                {`Thank you for your patience, we're working hard to get
                everyone onboarded as soon as possible whilst maintaining a
                fantastic service for our members. Due to high demand, this
                could take a couple of weeks. We will email you your
                invitation as soon as it's your turn to join.`}
              </p>
            </div>
          </div> */}

              <div className="mt-10 flex gap-4">
                <div className="w-1/2">
                  <p className="sm:text-md text-sm font-semibold text-gray-700">
                    How much is the membership?
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="sm:text-md text-sm text-gray-600">
                    {`It's ${
                      price ?? "£34"
                    }/month with a 21-day money-back guarantee. You can cancel for free at any time.`}
                  </p>
                </div>
              </div>
            </section>

            <div className="mb-24 mt-24">
              <SparklesIcon className="mx-auto mb-6 h-10 w-10 rounded-full bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 p-2 text-white" />
              <h4 className="bg-gradient-to-r from-gray-600 via-black to-gray-700 bg-clip-text text-center text-2xl font-bold text-transparent">
                Sign up to the best Pinnacle alert service now
              </h4>

              <div className="mt-6 flex items-center justify-center gap-4">
                {/* <input
              className="h-11 rounded-lg border-2 border-slate-200 px-3"
              placeholder="Your email"
            /> */}
                <Link
                  href="/sign-up"
                  className="rounded-lg bg-gradient-to-r from-slate-700 to-black px-4 py-2 text-lg font-semibold text-white hover:opacity-90"
                >
                  Get started
                </Link>
              </div>
            </div>

            <article className="mx-auto mb-32 max-w-lg rounded-lg border-2 border-gray-200 bg-gray-100 p-6">
              <p className="text-md text-gray-600">
                Hey there!
                <br />
                <br />
                {`I'm Henry, the founder of Pinnacle Odds Dropper 👋.`}
                <br />
                <br />
                {`Thanks for stopping by and checking out the service. It's amazing
            to be building a community of like-minded bettors.`}
                <br />
                <br />
                Back in 2019, I got into value betting and had a blast using
                RebelBetting and similar software. But I hit a roadblock when I
                got limited on most of the bookmakers they supported. Plus, I
                always felt like I was just blindly taking bets - I wanted to
                have more control over my strategy.
                <br />
                <br />
                {`So I started searching for a reliable Pinnacle odds drop
            alert service to help me find value bets on the 100+ bookmakers in
            my country that RebelBetting doesn't cover. Unfortunately, I
            couldn't find anything that fit the bill.`}
                <br />
                <br />
                {`Being a software engineer, I decided to build one for myself. It wasn't easy, but
            the flexibility it gave me was worth every minute.`}
                <br />
                <br />
                {`Now I'm thrilled to be able to share it with you guys. The community we're
            building is awesome and seeing people find success with it is
            incredibly rewarding.`}
                <br />
                <br />
                {`So thanks for being here, whether you're new
            or a member already. Have a great day!`}
                <br />
                <br />
                Best,
                <br />
                Henry - Pinnacle Odds Dropper founder
              </p>
            </article>
          </div>
          <footer className="mb-10 flex flex-col items-center justify-center gap-2">
            <p className="text-xs text-gray-600">
              Copyright Pinnacle Odds Dropper {new Date().getFullYear()}
            </p>
            <a
              href="https://www.begambleaware.org/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                src="/images/landing/begambleaware.png"
                alt="Be gamble aware logo"
                width={200}
                height={50}
                className=""
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </a>
            <div className="max-w-lg">
              <p className="p-4 text-justify text-xs text-gray-600">
                This site is strictly for educational and informational purposes
                only and does not involve any real-money betting. If you or
                someone you know has a gambling problem and wants help, call
                1-800-GAMBLER. This service is intended for adults aged 18 and
                over only.
              </p>
            </div>
            <div className="flex gap-2">
              <a
                className="text-xs text-gray-600"
                href="https://www.termsfeed.com/live/70ba6b2f-2a34-4226-a57a-b5220b8f01b4"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              <span className="text-xs text-gray-600">|</span>
              <a
                className="text-xs text-gray-600"
                href="https://www.termsfeed.com/live/befbf79b-b3ca-4b22-a159-3df9dd9dfa96"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              <span className="text-xs text-gray-600">|</span>
              <a
                className="text-xs text-gray-600"
                href="mailto:henry@pinnacleoddsdropper.com"
              >
                Contact us
              </a>
            </div>
          </footer>
        </div>
        <div className="landing-bg-main">
          <div className="landing-bg-content"></div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
